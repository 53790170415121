$(document).ready(function () {

	var $pageId = $('#apply-password-reset');
	if (!$pageId.length) {
		return;
	}

	$('#change-password-form').on("submit", function (e) {
		const $errorPasswordInequality = $('#error-password-inequality')
		const password = $('#password').val()
		const password2 = $('#password2').val()
		if (!password || password !== password2) {
			e.preventDefault();
			$errorPasswordInequality.show();
			return;
		}
		$errorPasswordInequality.hide();
	});

});
