$(document).ready(function () {

	const $w = $('#who-are');
	if (!$w.length) {
		return
	}

	window.reCaptchaVerified = function () {
		const submit = $('#submit-button');
		submit.prop("disabled", false);
	}

});
