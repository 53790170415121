$(document).ready(function () {

	const $v = $('#vantaggi');
	if (!$v.length) {
		return
	}

	// FORM 1
	let $resultPriceContainerForm1 = $('#form1 div#resultPriceContainer');
	let $convenienceForm1 = $('#form1 div#convenience');
	let $not_convenienceForm1 = $('#form1 div#not_convenience');

	function form1() {
		// FORM 1
		let $form1 = $('#form1');

		let $input_weight = $('#form1 input#weight');
		let $input_price = $('#form1 input#price');
		let $selectFuel = $('#form1 select#combustibile_1');
		let $result = $('#form1 strong#result');
		let $result2 = $('#form1 strong#result2');
		let $result3 = $('#form1 strong#result3');
		let $resultPrice1 = $('#form1 strong#resultPrice');
		let $resultFuel = $('#form1 strong#resultFuel');
		let $resultPrice2 = $('#form1 strong#resultPrice2');

		$input_price.on("click", function () {
			$(".cost").css("opacity", "1");
			$input_weight.val("");
			$(".weight").css("opacity", ".5");
		});

		$input_weight.on("click", function () {
			$(".weight").css("opacity", "1");
			$input_price.val("");
			$(".cost").css("opacity", ".5");
		});

		$form1.on('submit', function (e) {
			e.preventDefault();
			// hide
			hideResultForm1();
			// input
			let input_weight = $input_weight.val();
			let input_price = $input_price.val();
			if (input_weight) {
				// nocciolo
				let nocciolo_price = $form1.data("nocciolo-price");
				// let nocciolo_efficiency = $form1.data("nocciolo-efficiency");

				// fuel
				let fuel_label = $selectFuel.children('option:selected').data('label');
				let fuel_price = $selectFuel.children('option:selected').data('price');
				let fuel_efficiency = $selectFuel.children('option:selected').data('efficiency');

				// result
				let resultPrice1Val = input_weight * fuel_price; // prezzo fuel
				$resultPrice1.text("€ " + (resultPrice1Val).toFixed(2) );
				$resultFuel.text(fuel_label);

				let resultPrice2Val = input_weight * fuel_efficiency * nocciolo_price; // prezzo equivalente in nocciolo
				$resultPrice2.text("€ " + (resultPrice2Val).toFixed(2));

				$result.text(input_weight + " Kg");
				$result2.text(fuel_label);
				$result3.text((input_weight * fuel_efficiency).toFixed(0) + " Kg"); // peso equivalente in nocciolo

				// convenience
				let convenience = resultPrice2Val < resultPrice1Val;
				if (convenience) {
					$convenienceForm1.css('display', 'block');
				} else {
					$not_convenienceForm1.css('display', 'block');
				}
				$resultPriceContainerForm1.show("slow");
			} else if (input_price) {
				// nocciolo
				let nocciolo_price = $form1.data("nocciolo-price");
				// let nocciolo_efficiency = $form1.data("nocciolo-efficiency");

				// fuel
				let fuel_label = $selectFuel.children('option:selected').data('label');
				let fuel_price = $selectFuel.children('option:selected').data('price');
				let fuel_efficiency = $selectFuel.children('option:selected').data('efficiency');

				// result
				$resultPrice1.text((input_price / fuel_price).toFixed(0) + " Kg");
				$resultFuel.text(fuel_label);

				let resultPrice2Val = (input_price / fuel_price) * fuel_efficiency; // peso equivalente in nocciolo
				$resultPrice2.text((resultPrice2Val).toFixed(0) + " Kg");

				$result.text("€ " + input_price);
				$result2.text(fuel_label);
				let resultPrice1Val = resultPrice2Val * nocciolo_price; // prezzo equivalente nocciolo
				$result3.text("€ " + resultPrice1Val.toFixed(2));
				// convenience
				let convenience = resultPrice1Val < input_price;
				if (convenience) {
					$convenienceForm1.css('display', 'block');
				} else {
					$not_convenienceForm1.css('display', 'block');
				}
				$resultPriceContainerForm1.show("slow");
			} else {
				return null;
			}
		});
	}

	form1();

	function hideResultForm1() {
		$resultPriceContainerForm1.hide();
		$convenienceForm1.css('display', 'none');
		$not_convenienceForm1.css('display', 'none');
	}


});
