$(document).ready(function () {
	var $pageId = $('#coupon-page');
	if (!$pageId.length) {
		return;
	}

	$('.coupon-slider').slick({
		dots: true,
	});
	
});