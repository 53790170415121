$(document).ready(function() {

	const $cs = $('#calcolo-spedizione');
	if (!$cs.length) {
		return
	}

	/**
	 * CAP and address validation
	 */

	if ($('input.addressProvince').val() == "") {
		$('#shipping-calc-extras input.styled-checkbox').attr('disabled', true);
		$('#submit').attr('disabled', true);
		$('#computed-shipping-fare').text(`€ --`);
		$('#computed-shipping-fare').attr("data-price",`€ --`);
		$('#computed-shipping-fare').attr("data-vat_price",`€ --`);
	}

	// ----------------------------------------------- Addresses forms cap/city dropdowns
	if ($('input.addressPostalCode').val() != "") {
		findCap();
	}


	$('input.addressPostalCode').on('input', findCap);

	function findCap() {
		const $capInput = $('input.addressPostalCode')[0];
		const $postalCode = $($capInput);
		const val = $postalCode.val();
		// get the parent form

		const $form = $($capInput.form);
		const fid = $form.attr('id');

		const $cities = $(`#${fid} select.addressCity`);
		const $provinces = $(`#${fid} input.addressProvince`);
		const $spinner = $(`#${fid} .cap-spinner`);
		const $capErr = $(`#${fid} .cap-msg`);
		const $addressId = $('#shipping-calc-address-id');
		const $extras = $('#shipping-calc-extras input.styled-checkbox');

		// reset form state
		$postalCode.removeClass('is-invalid');
		$postalCode.removeClass('is-valid');
		$cities.find('option:gt(0)').remove();
		$cities.val($cities.find('option:first').val());
		$cities.attr('disabled', true);
		$provinces.val(null);
		$extras.attr('disabled', true);

		// reset address id (new stub address will be created)
		$addressId.val(0);

		function handleCapError() {
			$capErr.fadeIn();
			$postalCode.addClass('is-invalid');
			$('#computed-shipping-fare').text(`€ --`);
			$('#submit').attr('disabled', true);
		}

		if ($capErr.is(':visible')) {
			$capErr.fadeOut();
		}

		if (val.length < 5) {
			// input is clear
			if (val.length) {
				$postalCode.addClass('is-invalid');
				$('#computed-shipping-fare').text(`€ --`);
				$('#submit').attr('disabled', true);
				$('#computed-shipping-fare').data("price",`€ --`);
				$('#computed-shipping-fare').data("vat_price",`€ --`);
			}
			return;
		}

		//$spinner.fadeIn();

		const req = $.ajax({
			url: '/api/postalcode?postalCode=' + $postalCode.val() + '&order=city&results=1000',
			method: 'GET',
			contentType: 'application/json'
		});

		req.always(function() {
			//$spinner.fadeOut();



		});
		req.done(function (res) {
			const addrs = res.items;
			const len = addrs.length;
			for (i = 0; i < len; i++) {
				$cities.append($('<option/>')
					.val(addrs[i].id)
					.text(addrs[i].city)
					.data({
						acronym: addrs[i].acronym,
						province: addrs[i].province
					})
				);
			}

			$cities.attr('disabled', len === 0);

			if (!len) {
				handleCapError();
				$postalCode.trigger('postalcode-retrieved', [false]);
				return;
			}

			$postalCode.addClass('is-valid');

			if (len === 1) {
				$cities.val($cities.find('option:last').val());
				$cities.attr('readonly', true);
				$provinces.val(`${addrs[0].province} (${addrs[0].acronym})`);
				$postalCode.trigger('postalcode-retrieved', [true]);
				$extras.removeAttr('disabled');
				calculateShipping();
				return;
			}

			$cities.attr('readonly', false);

			$cities.on('change', function() {
				const $selected = $(this).find(':selected');
				$provinces.val(addrs ? `${$selected.data('province')} (${$selected.data('acronym')})` : null);
				$extras.removeAttr('disabled');
				calculateShipping();
			});

			$postalCode.trigger('postalcode-retrieved', [true]);
		});

		req.fail(function (result) {
			$cities.children('option:gt(0)').remove();
			$cities.val($cities.find('option:first').val());
			$provinces.val(null);
			const serverResponse = JSON.parse(result.responseText);
			if (serverResponse.Field === 'postalCode') {
				handleCapError();
				return;
			}
			if (serverResponse.Error) {
				console.error(serverResponse.Error);
				window.translations.error[serverResponse.Error] ?
						showErrorToast(window.translations.error[serverResponse.Error]):
						showErrorToast(window.translations.error.address_update);
			} else {
				showErrorToast(window.translations.error.update_address);
			}
			$postalCode.trigger('postalcode-retrieved', [false]);
		});

	};

	$('#shipping-calc-type').on('change', function(){
		if (!$('#submit').attr('disabled')) {
			calculateShipping();
		}
	});
	$('input.styled-checkbox').on('click', function(){
		if (!$('#submit').attr('disabled')) {
			calculateShipping();
		}
	});
	function calculateShipping() {
		// Call webservice to compute fare based on customer cart
		const data = $('#shipment-address-calc-form').serializeArray();

		const $urlidentifier = $('#shipment-address-calc-form').data('urlidentifier');
		const req = $.ajax({
			url: `/api/frontend/order/${$urlidentifier}/shipment-calculator`,
			data: data,
			method: 'POST',
		});

		req.done(function (value) {
			if ($('#iva-switch').is(':checked')) {
				$('#computed-shipping-fare').text(`€ ${value.ShipmentPriceTotal.toFixed(2)}`);
			} else {
				$('#computed-shipping-fare').text(`€ ${value.ShipmentPriceNet.toFixed(2)}`);
			}
			$('#computed-shipping-fare').data("price",`€ ${value.ShipmentPriceNet.toFixed(2)}`);
			$('#computed-shipping-fare').data("vat_price",`€ ${value.ShipmentPriceTotal.toFixed(2)}`);
			// $('#computed-shipping-fare-delivery').text(`h ${value.DeliveryTime.toFixed(2)}`);
			$('#submit').attr('disabled', false)
		});

		req.fail(function (result) {
			$('#computed-shipping-fare').text(`€ --`);
			$('#submit').attr('disabled', true);
			$('#computed-shipping-fare').data("price",`€ --`);
			$('#computed-shipping-fare').data("vat_price",`€ --`);
			console.error(result);
			showErrorToast(window.translations.globals.error.generic_error);
		});
	}

	/**
	 * Customer's shipment address list
	 */

	const $addrContainer = $('#addresses-container');
	const $shippingForm = $('#shipment-address-calc-form');
	const $submit = $('#shipment-address-calc-form input[type="submit"]');
	const $cap = $('#shipping-calc-cap');
	const $province = $('#shipping-calc-province');
	const $city = $('#shipping-calc-city');
	const $addressId = $('#shipping-calc-address-id');

	$('#change-address').on('click', function(e) {
		e.preventDefault();
		$addrContainer.toggle();
	});

	$addrContainer.on('click', '.address-select', function(e) {
		e.preventDefault();
		const id = $(this).data('id');
		const req = $.ajax({
			url: '/api/frontend/address/' + id,
			method: 'GET',
			contentType: 'application/json'
		});

		req.done(function (value) {
			$addrContainer.hide('slow', function() {
				$cap.val(value.postalCodeValue);
				$cap.one('postalcode-retrieved', function(e) {
					$city.val(value.postalCode.id);
					$city.trigger('change');
					$city.attr('readonly', true);
					$addressId.val(value.id);
				});
				$cap.trigger('input');

			});

		});

		req.fail(function (result) {
			console.error(result);
			showErrorToast(window.translations.globals.error.generic_error);
		});

	});
});
