$(document).ready(function () {


	const $cs = $('#conditioned');
	if (!$cs.length) {
		return
	}

	/**
	 * Customer's shipment address list
	 */

	const $addrContainer = $('#addresses-container');
	const $submit = $('#conditioned-shipping-address-form input[type="submit"]');
	const $name = $('#shipping-name');
	const $surname = $('#shipping-surname');
	const $companyName = $('#shipping-company');
	const $address = $('#shipping-address');
	const $streetNumber = $('#shipping-streetNumber');
	const $country = $('#shipping-country');
	const $province = $('#shipping-province');
	const $abroad = $('#shipping-abroad');
	const $phone = $('#shipping-phone');
	const $cap = $('#shipping-cap');
	const $city = $('#shipping-city');

	$('#change-address').on('click', function (e) {
		e.preventDefault();
		$addrContainer.toggle();
	});

	$addrContainer.on('click', '.address-select', function (e) {
		e.preventDefault();
		const id = $(this).data('id');
		const req = $.ajax({
			url: '/api/frontend/address/' + id,
			method: 'GET',
			contentType: 'application/json'
		});

		req.done(function (value) {
			$addrContainer.hide('slow', function () {
				$name.val(value.name);
				$surname.val(value.surname);
				$companyName.val(value.companyName);
				$address.val(value.address);
				$streetNumber.val(value.streetNumber);
				$country.val(value.country);
				$province.val(value.province);
				$abroad.val(value.abroad);
				$phone.val(value.phone);
				$cap.val(value.postalCodeValue);
				$cap.one('postalcode-retrieved', function (e) {
					$city.val(value.postalCode.id);
					$city.trigger('change');
					$city.attr('readonly', true);
				});
				$cap.trigger('input');

				updateShownAddressFields();
			});

		});

		req.fail(function (result) {
			console.error(result);
			showErrorToast(window.translations.globals.error.generic_error);
		});

	});

	// form
	let $form = $('#conditioned-shipping-address-form');

	$form.on('submit', function (e) {
		e.preventDefault();
		$(".t-button").attr("disabled", true);
		let req = $.ajax({
			url: $form.attr('action'),
			data: $(this).serialize(),
			method: 'POST',
		});

		req.done(function (obj) {
			$(".t-button").removeAttr("disabled");
			freezeSelectedConsentCheckboxes();
			window.location.href = `/${$form.data("language")}/checkout/conditioned-completed/${obj.OrderId}`;
		});

		req.fail(function (result) {
			$(".t-button").removeAttr("disabled");
			freezeSelectedConsentCheckboxes();
			console.error(result);
			switch (result.responseJSON.ErrorCode) {
				case "missing_personal_consent":
				case "missing_purchase_consent":
				case "missing_shipment_consent":
					showErrorToast(window.translations.error[result.responseJSON.ErrorCode])
					break;
				default:
					showErrorToast(window.translations.globals.error.generic_error);
					break;
			}
		});
	});

	$country.on('change', function () {
		updateShownAddressFields();
	});

	function updateShownAddressFields() {
		const italianAddress = $('.italian-address');
		const abroadAddress = $('.abroad-address');
		const italianInputs = italianAddress.find('input');
		if ($country.val() === 'IT') {
			italianAddress.show();
			abroadAddress.hide();
			italianInputs.attr('required', true);
		} else {
			italianAddress.hide();
			abroadAddress.show();
			italianInputs.attr('required', false);
		}
	}

	function freezeSelectedConsentCheckboxes() {
		$('#conditions').find('input[type=checkbox]:checked').attr('disabled', '');
	}

});
