import {notify, revalidate, removeCustomValidity} from "./index";

$(document).ready(function () {

	const $profile = $('#profile-panel');
	if (!$profile.length) {
		return
	}
	/**
	 * CAP and address validation
	 */

	// ----------------------------------------------- Addresses forms cap/city dropdowns
	$('input.addressPostalCode').on('input', function () {
		const $postalCode = $(this);
		const val = $postalCode.val();

		// get the parent form

		const $form = $(this.form);
		const fid = $form.attr('id');

		const $cities = $(`#${fid} select.addressCity`);
		const $provinces = $(`#${fid} input.addressProvince`);
		const $spinner = $(`#${fid} .cap-spinner`);
		const $capErr = $(`#${fid} .cap-msg`);

		// reset form state
		$postalCode.removeClass('is-invalid');
		$postalCode.removeClass('is-valid');
		$cities.find('option:gt(0)').remove();
		$cities.val($cities.find('option:first').val());
		$cities.attr('disabled', true);
		$provinces.val(null);

		function handleCapError() {
			$capErr.fadeIn();
			$postalCode.addClass('is-invalid');
		}

		if ($capErr.is(':visible')) {
			$capErr.fadeOut();
		}

		if (val.length < 5) {
			// input is clear
			if (val.length) {
				$postalCode.addClass('is-invalid');
			}
			return;
		}

		$spinner.fadeIn();

		const req = $.ajax({
			url: '/api/postalcode?postalCode=' + $postalCode.val() + '&order=city&results=1000',
			method: 'GET',
			contentType: 'application/json'
		});

		req.always(function () {
			$spinner.fadeOut();
		});
		req.done(function (res) {
			const addrs = res.items;
			const len = addrs.length;
			for (let i = 0; i < len; i++) {
				$cities.append($('<option/>')
					.val(addrs[i].id)
					.text(addrs[i].city)
					.data({
						acronym: addrs[i].acronym,
						province: addrs[i].province
					})
				);
			}

			$cities.attr('disabled', len === 0);

			if (!len) {
				handleCapError();
				return;
			}

			$postalCode.addClass('is-valid');
			if (len === 1) {
				$cities.val($cities.find('option:last').val());
				$cities.attr('readonly', true);
				$provinces.val(`${addrs[0].province} (${addrs[0].acronym})`);
				return;
			}

			$cities.attr('readonly', false);

			$cities.on('change', function () {
				const $selected = $(this).find(':selected');
				$provinces.val(addrs ? `${$selected.data('province')} (${$selected.data('acronym')})` : null);
			});
		});

		req.fail(function (result) {
			$cities.children('option:gt(0)').remove();
			$cities.val($cities.find('option:first').val());
			$provinces.val(null);
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				if (serverResponse.Field === 'postalCode') {
					handleCapError();
					return;
				}
				if (serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_update);
				}
			} else {
				showErrorToast(window.translations.error.address_update);
			}
		});
	});

	/**
	 * Billing/shipping address form handling
	 */
	if ($('#billing-address-form')) {
		removeCustomValidity('#billing-address-form');
	}

	if ($('#shipping-address-form')) {
		removeCustomValidity('#shipping-address-form');
	}

	$('#billing-address-form, #shipping-address-form').on('submit', function (e) {
		e.preventDefault();

		// Disable submit button to avoid multiple insertions
		const submitButton = $(this).find("input[type=submit]" );

		const $form = $(this);
		$form.addClass('was-validated');
		if (!$form[0].checkValidity()) {
			return
		}
		$(".t-button").attr("disabled", true);
		const vals = $form.serializeArray();
		const data = {};

		$.map(vals, function (n, i) {
			data[n['name']] = n['value'];
		});

		data.isCompany = data.isCompany === 'true';

		const method = $(this).attr('method');
		let url = $(this).attr('action');

		submitButton.attr("disabled", true);
		const req = $.ajax({
			url: url,
			data: JSON.stringify(data),
			method: method,
			dataType: 'json',
			contentType: 'application/json'
		});

		req.done(function (result) {
			// update the url
			$form.removeClass('was-validated');
			let callback = function () {
			};
			if ($form.attr('id') === 'shipping-address-form') {
				callback = function () {
					window.location.href = `/profile/shipping-address`;
					submitButton.attr("disabled", false);
				};

			}
			notify('success', window.translations.address_updated, callback);
			location.reload();
		});

		req.fail(function (result) {
			submitButton.attr("disabled", false);
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				revalidate(serverResponse, $form);
				if (serverResponse && serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_update);
				}
			} else {
				showErrorToast(window.translations.error.address_update);
			}
		});
	});

	/**
	 * Country address behaviour
	 */
	$('select#billing-country, #shipping-country').on('change', function () {
		const country = $(this).val();
		const $itContainer = $('.italian-address');
		const $abContainer = $('.abroad-address');
		const $ta = $abContainer.find('textarea');

		const $cap = $('input[name="postalCodeValue"]');
		const $province = $('select[name="postalCodeId"]');

		if (country === 'IT') {
			$itContainer.show();
			$abContainer.hide();
			$cap.attr('disabled', null);
			$province.attr('disabled', null);
			$ta.attr('disabled', 'disabled');
		} else {
			$itContainer.hide();
			$abContainer.show();
			$cap.attr('disabled', 'disabled');
			$province.attr('disabled', 'disabled');
			$ta.attr('disabled', null);
		}
	});

	/***** Customer data form handling *****/
	const $personalDataForm = $('#personalDataForm');

	if ($personalDataForm) {
		var $error = $('#personalDataForm div#error');
		var $success = $('#personalDataForm div#success');
		removeCustomValidity('#personalDataForm');

		$personalDataForm.on('submit', function (e) {
			e.preventDefault();
			const $form = $(this);

			$form.addClass('was-validated');
			if (!$form[0].checkValidity()) {
				return
			}

			$(".t-button").attr("disabled", true);

			const vals = $form.serializeArray();
			const data = {};

			$.map(vals, function (n, i) {
				data[n['name']] = n['value'];
			});

			data.isCompany = data.isCompany === 'true';

			var req = $.ajax({
				url: $form.attr('action'),
				data: JSON.stringify(data),
				method: 'PUT',
				contentType: 'application/json'
			});

			req.done(function (_) {
				$(".t-button").removeAttr("disabled");
				notify('success', window.translations.updated)
			});

			req.fail(function (result) {
				$(".t-button").removeAttr("disabled");
				if (result.status === 400 || result.status === 403) {
					const serverResponse = result.responseJSON;
					if (serverResponse && serverResponse.Error) {
						console.log(serverResponse);
						revalidate(serverResponse, $form);
						switch (serverResponse.Error) {
							case "pec address already present":
								showErrorToast(`${window.translations.error.pecAlreadyPresent} ${serverResponse.Args[0]}`);
								break;
							case "text contains non-alphanumeric characters":
								showErrorToast(window.translations.error.name);
								break;
							default:
								window.translations.error[serverResponse.Error] ?
										showErrorToast(window.translations.error[serverResponse.Error]):
										showErrorToast(window.translations.error.update_personal_data);
						}
					}
				} else {
					showErrorToast(window.translations.error.update_personal_data);
				}
			});

			// accepted consent
			let dataCheckbox = jQuery('#conditions input[type=checkbox]:checked:not(:disabled)').map(
				function () {
					return {"name": this.name, "value": this.checked}
				}).get();

			const dataAcceptedConsent = {};
			dataAcceptedConsent.customerId = Number(data["id"]);

			for (let i = 0; i < dataCheckbox.length; i++) {
				let name = dataCheckbox[i].name.replace('checkbox', 'consentId');
				dataAcceptedConsent.consentDocumentId = Number(data[name]);
				postAccepetedConsent(dataAcceptedConsent)
			}

		});
	}

	function postAccepetedConsent(data) {
		let reqAcceptedConsent = $.ajax({
			url: "/api/accepted-consent/",
			data: JSON.stringify(data),
			method: 'POST',
			contentType: 'application/json'
		});

		reqAcceptedConsent.done(function (result) {
			window.location.reload();
		});

		reqAcceptedConsent.fail(function (result) {
			showErrorToast(window.translations.error.consent_error);
		});
	}

	/**
	 * Password update form handling
	 */
	if ($('#passwordChangeForm')) {
		removeCustomValidity('#passwordChangeForm');
	}
	$('#passwordChangeForm').on('submit', function (e) {
		e.preventDefault();
		const $form = $(this);

		// Reset validation state
		for(let i=0; i <$form[0].length; i++) {
			$form[0][i].setCustomValidity('');
		};

		$form.addClass('was-validated');
		if (!$form[0].checkValidity()) {
			return
		}

		// Re-highlight fields
		//$form[0].reportValidity();

		$(".t-button").attr("disabled", true);
		const vals = $form.serializeArray();
		const data = {};

		$.map(vals, function (n, i) {
			data[n['name']] = n['value'];
		});

		const req = $.ajax({
			url: $form.attr('action'),
			data: JSON.stringify(data),
			method: 'PUT',
			contentType: 'application/json'
		});

		req.done(function (_) {
			$(".t-button").removeAttr("disabled");
			notify('success', window.translations.password_update);
		});

		req.fail(function (result) {
			$(".t-button").removeAttr("disabled");
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				revalidate(serverResponse, $form);
				if (serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.update_personal_data);
				}
			}
			else {
				showErrorToast(window.translations.error.update_personal_data);
			}
		});
	});

	/**
	 * Deletion of shipping address from list
	 */
	$("#shipment-addresses-container").on("click", ".address .remove", function () {
		const $box = $(this).closest(".address");
		var req = $.ajax({
			url: '/api/frontend/address/' + $(this).attr("data-id"),
			method: 'DELETE',
			contentType: 'application/json'
		});

		req.done(function () {
			$box.slideUp();
		});

		req.fail(function (result) {
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				if (serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_delete);
				}
			} else {
				showErrorToast(window.translations.error.address_delete);
			}
		});
	});

	const $country = $('#shipping-country');

	$country.on('change', function () {
		updateShownAddressFields();
	});

	function updateShownAddressFields() {
		const italianAddress = $('.italian-address');
		const abroadAddress = $('.abroad-address');
		const italianInputs = italianAddress.find('input');
		if ($country.val() === 'IT') {
			italianAddress.show();
			abroadAddress.hide();
			italianInputs.attr('required', true);
		} else {
			italianAddress.hide();
			abroadAddress.show();
			italianInputs.attr('required', false);
		}
	}

});
