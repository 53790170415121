import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';


import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';

library.add(faCircle, faBars, faUserCircle, faShoppingCart, faFacebookSquare, faInstagram, faExclamationTriangle, faInfoCircle, faCaretDown, faArrowAltCircleLeft, faArrowAltCircleRight);

dom.watch();

$(document).ready(function () {

	//--- define the caldini namespace
	/**
	 * Adds @quantity of a product with id @productId to cart.
	 * @param productId
	 * @param quantity
	 */
	caldini.purchase = function(productId, quantity) {
		const req = $.ajax({
			url: '/purchase',
			data: {
				productID: productId,
				quantity: quantity,
			},
			method: 'POST',
		});

		req.done(function () {
			hideError();
			window.location.href = `/${window.caldini.locale}/cart`;
		});

		req.fail(function (result) {
			var submit = "[data-submit=" + productId + "]";
			$(submit).removeAttr('disabled');
			switch (result.status) {
				case 401:
					// not authorized
					window.location.href = '/';
					break;
				case 403:
					// product can not be added to cart due to shipping incompatibility
					showErrorToast(window.translations.no_add_error);
					break;
				default:
					showErrorToast(window.translations.globals.error.generic_error);
			}
		});
	};

	// -------------------------------------- Fixed menu when scrolling
	$(window).bind('scroll', function () {
		const $scrollLogo = $('.scroll_logo');
		if ($(window).scrollTop() > 220) {
			$('.navbar').addClass('fixed');
			$scrollLogo.show();
		} else {
			$('.navbar').removeClass('fixed');
			$scrollLogo.hide();
		}
	});

	// ----------------------------------------------- Hamburger mobile
	const $tabletmenu = $(".c_hamburger");
	const $langmenu = $(".language-burger");
	const $mobilemenu = $(".hamburger");
	const $accountmenu = $(".account-burger");
	$tabletmenu.on("click", function () {
		$accountmenu.attr('aria-expanded') == "true" ? $accountmenu.click() : null;
		$langmenu.attr('aria-expanded') == "true" ? $langmenu.click(): null;
		$(this).toggleClass("open");
		$(".navbar--nove").toggleClass("green_bg");
		$(".header_logo").toggle();

	});
	$mobilemenu.on("click", function () {
		$accountmenu.attr('aria-expanded') == "true" ? $accountmenu.click() : null;
		$langmenu.attr('aria-expanded') == "true" ? $langmenu.click(): null;

	});
	$accountmenu.on("click", function () {
		$mobilemenu.attr('aria-expanded') == "true" ? $mobilemenu.click(): null;
		$langmenu.attr('aria-expanded') == "true" ? $langmenu.click(): null;
		$tabletmenu.attr('aria-expanded') == "true" ? $tabletmenu.click(): null;
	});

	$langmenu.on("click", function () {
		$accountmenu.attr('aria-expanded') == "true" ? $accountmenu.click(): null;
		$tabletmenu.attr('aria-expanded') == "true" ? $tabletmenu.click(): null;
		$mobilemenu.attr('aria-expanded') == "true" ? $mobilemenu.click(): null;
	});

	//------------------------------------------------ Language submenu mobile
	$('.dropdown-submenu a.test').on("click", function(e){
		$(this).next('ul').toggle();
		e.stopPropagation();
		e.preventDefault();
	});
	//---------------------------------------------------- IVA checkbox
	$('#iva-switch').on("change.bootstrapSwitch", function (e) {
		const custID = $(this).data("customer");
		let newVat = e.target.checked ? "yes" : "no";
		const data = {
			showVat: newVat,
		};
		let req = $.ajax({
			url: `/api/frontend/customer/${custID}`,
			data: JSON.stringify(data),
			method: 'PATCH',
			contentType: 'application/json'
		});

		req.done(function (result) {
			$(".vat-change").each(function (index, obj)
			{
				let new_text = $(this).data("price");
				if (newVat === "yes")  {
					new_text = $(this).data("vat_price");
				}
				window.showVat = newVat;
				$(this).text(new_text);
			});
		});

		req.fail(function (result) {
			showErrorToast(window.translations.globals.error.generic_error);
		});
	});

	// -------------------------------------------------------- Counter
	$(".counter_plus").on("click", function () {
		var currentValue = parseInt($(this).prev().val());
		if (currentValue > 0) {
			$(this).prev().val(currentValue + 1);
			$(this).trigger("quantityChanged", [(currentValue + 1)]);
		}
	});
	$(".counter_minus").on("click", function () {
		var currentValue = parseInt($(this).next().val());
		if (currentValue > 1) {
			$(this).next().val(currentValue - 1);
			$(this).trigger("quantityChanged", [(currentValue - 1)]);
		}
	});
	$(".counter_number").on("change", function (){
		const quantity = Number($(this).val());
		if (quantity > 0 && quantity <1000) {
			$(this).trigger("quantityChanged", quantity);
		} else {
			$(this).val("1");
			$(this).trigger("quantityChanged", 1);
			showErrorToast(window.translations.globals.error.positive);
		}
	});

	//--------------------------------------------------------- Product-list purchase buttons
	$(".item .add .button_purchase").on("click", function () {
		// Google ads: Event snippet for Aggiunta al carrello CALDOLIVA conversion page
		if (window.caldini.cartCount == 0) {
			gtag('event', 'conversion', {'send_to': 'AW-997987610/ZT9pCMmj2tADEJqq8NsD'});
		}

		let $productID = $(this).attr("id");
		let $qntID = 'input#' + $(this).attr("id") + 'quantity';
		let $quantity = $($qntID);
		$(this).attr("disabled", true);
		caldini.purchase($productID, $quantity.val());
	});

	// ----------------------------------------------- Checkout steps
	$(".to_step_one").click(function () {
		$(".steps").slideUp();
		$("#step_one").fadeIn();
		$(".nav .nav-link").removeClass("active");
		$(".nav-link#nav_step_one").addClass("active");
	});
	$(".to_step_two").click(function () {
		$(".steps").slideUp();
		$("#step_two").fadeIn();
		$(".nav .nav-link").removeClass("active");
		$(".nav-link#nav_step_two").addClass("active");
	});
	$(".to_step_three").click(function () {
		$(".steps").slideUp();
		$("#step_three").fadeIn();
		$(".nav .nav-link").removeClass("active");
		$(".nav-link#nav_step_three").addClass("active");
	});
	$(".to_step_four").click(function () {
		$(".steps").slideUp();
		$("#step_four").fadeIn();
		$(".nav .nav-link").removeClass("active");
		$(".nav-link#nav_step_four").addClass("active");
	});
	$(".to_step_final").click(function () {
		$(".steps").slideUp();
		$("#step_final").fadeIn();
		$(".nav").hide();
	});
	$("#step_one form, #step_two form, #step_three form, #step_four form").submit(function (e) {
		e.preventDefault();
	});

	// ---------------------------------------------- Remove address
	$(".address .remove").on("click", function () {
		$(this).closest(".address").slideUp();
	});
	// ----------------------------------------------- Edit address
	$("#edit_address form").submit(function (e) {
		e.preventDefault();
	});
	// ----------------------------------------------- Logout
	$("#button_logout").on("click", function (e) {
		e.preventDefault();
		const req = $.ajax({
			url: '/api/logout',
			data: {},
			method: 'POST',
		});

		req.done(function (obj) {
			window.location.href = '/';
		});

		req.fail(function (result) {
			console.error(result);
			if (result.status === 404) {
				window.location.href = '/';
			}
		});
	});

	// ----------------------------------------------- alert start

	window.showError = showError;
	window.hideError = hideError;
	window.showMessage = showMessage;
	window.hideMessage = hideMessage;

	$("#alertErrorClose").on("click", function () {
		hideError();
	});

	$("#alertMessageClose").on("click", function () {
		hideMessage();
	});

	function showError(text, autoClose) {
		$('#alertMessageError').text(text);
		$('#alertError').fadeIn();
		if (autoClose) {
			setTimeout(function () {
				hideError();
			}, 5000);
		}
	}

	function hideError() {
		$('#alertError').fadeOut();
	}

	function showMessage(text, autoClose) {
		$('#alertMessageText').text(text);
		$('#alertMessage').fadeIn();
		if (autoClose) {
			setTimeout(function () {
				hideMessage();
			}, 3000);
		}
	}

	function hideMessage() {
		$('#alertMessage').fadeOut();
	}

	// ----------------------------------------------- alert end

	// ----------------------------------------------- Toast start

	window.showErrorToast = showErrorToast;
	window.showMessageToast = showMessageToast;
	window.minSlides = minSlides;

	function showErrorToast(text) {
		if ($('.toast')[0]) {
			return
		} else
			Toastify({
				text: text,
				duration: 5000,
				gravity: "bottom",
				position: 'center', // `left`, `center` or `right`
				backgroundColor: "#aa0000",
				className: "toast",
				stopOnFocus: true, // Prevents dismissing of toast on hover
			}).showToast();
	}

	function showMessageToast(text) {
		if ($('.toast')[0]) {
			return
		} else
			Toastify({
				text: text,
				duration: 3000,
				gravity: "bottom",
				position: 'center', // `left`, `center` or `right`
				backgroundColor: "#497D51",
				className: "toast",
				stopOnFocus: true, // Prevents dismissing of toast on hover
			}).showToast();
	}

	/**
	 * Returns the slides to show
	 * @param int defSlides
	 * @returns int slides to show
	 */
	function minSlides(defSlides) {
		let itemsNo = $('.products .item').length;
		if (itemsNo < defSlides)
			return itemsNo;
		return defSlides;
	}

	// ----------------------------------------------- Toast end

	//-- tooltips

	$('[data-toggle="tooltip"]').each(function(){
		const options = {
			html: true
		};

		if ($(this)[0].hasAttribute('data-type')) {
			options['template'] =
				'<div class="tooltip ' + $(this).attr('data-type') + '" role="tooltip">' +
				'	<div class="tooltip-arrow"></div>' +
				'	<div class="tooltip-inner"></div>' +
				'</div>';
		}

		$(this).tooltip(options);
	});

	var $pageId = $('.home');
	if ($pageId.length) {
		$('.products').slick({
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: minSlides(4),
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: minSlides(3),
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				}
			]
		});

		$('.products').on('afterChange', function(event, slick, currentSlide, nextSlide){
			if((slick.slideCount - 1) == currentSlide){
				setTimeout(function(){
					slick.goTo(0);
				}, slick.getOption('autoplaySpeed'));
			}
		});
	}
});

export function displayVat() {
	if (window.showVat === "yes") return true;

	return false;
}

export function notify(state, text) {
	if (state !== 'error' && state !== 'success') {
		throw new Error('invalid state ' + state);
	}
	if (state === 'error') {
		showErrorToast(text);
	}
	if (state === 'success') {
		showMessageToast(text);
	}
}

export function revalidate(fieldError, $form) {
	let ffn = 'Field';
	if (fieldError.hasOwnProperty('field')) {
		ffn = 'field';
	}
	if (!fieldError[ffn] || fieldError[ffn] === "") {
		showErrorToast(window.translations.globals.error.generic_error);
		return;
	}
	const $name = fieldError[ffn].charAt(0).toLowerCase() + fieldError[ffn].substring(1);
	$form.removeClass('was-validated');
	const $inp = $('input[name=' + $name +']');
	if ($inp.get(0)) {
		$inp.get(0).setCustomValidity(fieldError['Error']);
		$form.addClass('was-validated');
	}
}
export function removeCustomValidity($form) {
	const $ins = $($form + ' :input');
	$ins.on('input', function() {
		$(".t-button").removeAttr("disabled");
		$(this).get(0).setCustomValidity('');
	});
}

