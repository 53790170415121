import {notify, revalidate, removeCustomValidity} from "./index";

$(document).ready(function() {

	const $container = $('.checkout-missingdata');
	if (!$container.length) {
		return
	}

	// init the error reporting divs
	const $err = $('#status-error');
	const $succ = $('#status-success');

	/**
	 * CAP and address validation
	 */

	const $addressPC = $('input.addressPostalCode');



	// ----------------------------------------------- Addresses forms cap/city dropdowns
	if ($addressPC.length && $addressPC.val()) {
		findCap();
	}

	$addressPC.on('input', findCap);

	function findCap() {
		const $capInput = $('input.addressPostalCode')[0];
		const $postalCode = $($capInput);
		const val = $postalCode.val();

		// get the parent form

		const $form = $($capInput.form);
		const fid = $form.attr('id');

		const $cities = $(`#${fid} select.addressCity`);
		const $provinces = $(`#${fid} input.addressProvince`);
		const $spinner = $(`#${fid} .cap-spinner`);
		const $capErr = $(`#${fid} .cap-msg`);

		// reset form state
		$postalCode.removeClass('is-invalid');
		$postalCode.removeClass('is-valid');
		$cities.find('option:gt(0)').remove();
		$cities.val($cities.find('option:first').val());
		$cities.attr('disabled', true);
		$provinces.val(null);

		function handleCapError() {
			$capErr.fadeIn();
			$postalCode.addClass('is-invalid');
		}

		if ($capErr.is(':visible')) {
			$capErr.fadeOut();
		}

		if (val.length < 5) {
			// input is clear
			if (val.length) {
				$postalCode.addClass('is-invalid');
			}
			return;
		}

		$spinner.fadeIn();

		const req = $.ajax({
			url: '/api/postalcode?postalCode=' + $postalCode.val() + '&order=city&results=1000',
			method: 'GET',
			contentType: 'application/json'
		});

		req.always(function() {
			$spinner.fadeOut();
		});
		req.done(function (res) {
			const addrs = res.items;
			const len = addrs.length;
			for (let i = 0; i < len; i++) {
				let selected = parseInt(addrs[i].id) === parseInt($postalCode.data("postalcodeid"));
				$cities.append($('<option/>')
					.val(addrs[i].id)
					.text(addrs[i].city)
					.data({
						acronym: addrs[i].acronym,
						province: addrs[i].province
					})
					.attr('selected', selected)
				);
				if (selected) {
					// Set province
					$provinces.val(`${addrs[i].province} (${addrs[i].acronym})`);
				}
			}

			$cities.attr('disabled', len === 0);

			if (!len) {
				handleCapError();
				return;
			}

			$postalCode.addClass('is-valid');
			if (len === 1) {
				$cities.val($cities.find('option:last').val());
				$cities.attr('readonly', true);
				$provinces.val(`${addrs[0].province} (${addrs[0].acronym})`);
				return;
			}

			$cities.attr('readonly', false);

			$cities.on('change', function() {
				const $selected = $(this).find(':selected');
				$provinces.val(addrs ? `${$selected.data('province')} (${$selected.data('acronym')})` : null);
			});
		});

		req.fail(function (result) {
			$cities.children('option:gt(0)').remove();
			$cities.val($cities.find('option:first').val());
			$provinces.val(null);
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				if (serverResponse.Field === 'postalCode') {
					handleCapError();
					return;
				}
				if (serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_update);
				}
			} else {
				showErrorToast(window.translations.error.address_update);
			}
		});
	};

	/**
	 * Billing address form handling
	 */
	const $usesame = $('#usefordelivery');
	const $useForDeliveryModal = $('#usefordelivery-modal');
	const $useForDeliveryCancel = $('#usefordelivery-confirm');
	const $hiddenSameAsDelivery = $('#sameasdelivery');
	const $hiddenForm = $('#sameasdelivery-form');

	$useForDeliveryCancel.on('click', function() {
		$usesame.prop('checked', false);
		$useForDeliveryModal.modal('hide');
		$usesame.trigger('change');
	});

	$usesame.on('change', function(e) {
		const checked = $(this).is(':checked');
		if (checked) {
			$useForDeliveryModal.modal('show');
		}
		$hiddenSameAsDelivery.val(checked ? 'yes' : null);
	});
	if ($('#billing-address-form')) {
		removeCustomValidity('#billing-address-form');
	}

	$('#billing-address-form').on('submit', function(e) {
		e.preventDefault();
		const $form = $(this);

		$form.addClass('was-validated');
		if (!$form[0].checkValidity()) {
			return
		}

		const vals = $form.serializeArray();
		const data = {};
		$(".t-button").attr("disabled", true);
		$.map(vals, function(n, i){
			data[n['name']] = n['value'];
		});

		data.isCompany = data.isCompany === 'true';

		const isBilling = data.type === 'billing';

		const method = $(this).attr('method');
		let url = $(this).attr('action');

		const req = $.ajax({
			url: url,
			data: JSON.stringify(data),
			method: method,
			dataType: 'json',
			contentType: 'application/json'
		});

		req.done(function (addr) {
			// read the value from
			if (isBilling) {
				$hiddenForm.submit();
				return
			}
			$(".t-button").removeAttr("disabled");
			window.location.reload()
		});

		req.fail(function (result) {
			$(".t-button").removeAttr("disabled");
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				revalidate(serverResponse, $form);
				if (serverResponse) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_update);
				}
			} else {
				showErrorToast(window.translations.error.address_update);
			}
		});
	});


	/**
	 * -- handle the shipping address form
	 */
	if ($('#shipping-address-form')) {
		removeCustomValidity('#shipping-address-form');
	}

	$('#shipping-address-form').on('submit', function(e) {
		e.preventDefault();
		e.stopPropagation();
		const $form = $(this);

		$form.addClass('was-validated');
		if (!$form[0].checkValidity()) {
			return;
		}

		$(".t-button").attr("disabled", true);
		const method = $(this).attr('method');
		let url = $(this).attr('action');
		const vals = $form.serializeArray();
		const req = $.ajax({
			url: url,
			data: vals,
			method: method,
		});

		req.done(function (addr) {
			// address has been updated, go to the next step
			window.location.href = `/${window.caldini.locale}/checkout/cart/summary`;
		});

		req.fail(function (result) {
			$(".t-button").removeAttr("disabled");
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				revalidate(serverResponse, $form);
				if (serverResponse) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
						showErrorToast(window.translations.error[serverResponse.Error]):
						showErrorToast(window.translations.error.address_update);
				}
			} else {
				showErrorToast(window.translations.error.address_update);
			}
		});
	});

	/**
	 * Country address behaviour
	 */
	$('select#billing-country, #shipping-country').on('change', function () {
		const country = $(this).val();
		const $itContainer = $('.italian-address');
		const $abContainer = $('.abroad-address');
		const $ta = $abContainer.find('textarea');

		const $cap = $('input[name="postalCodeValue"]');
		const $province = $('select[name="postalCodeId"]');

		if (country === 'IT') {
			$itContainer.show();
			$abContainer.hide();
			$cap.attr('disabled', null);
			$province.attr('disabled', null);
			$ta.attr('disabled', 'disabled');
			$usesame.prop('disabled', false);
		} else {
			$itContainer.hide();
			$abContainer.show();
			$cap.attr('disabled', 'disabled');
			$province.attr('disabled', 'disabled');
			$ta.attr('disabled', null);
			$usesame.prop('checked', false);
			$usesame.trigger('change');
			$usesame.prop('disabled', true);
		}
	});

	/**
	 * Deletion of shipping address from list
	 */
	$("#shipment-addresses-container").on("click", ".address .remove", function() {
		const $box = $(this).closest(".address");
		var req = $.ajax({
			url: '/api/frontend/address/' + $(this).attr("data-id"),
			method: 'DELETE',
			contentType: 'application/json'
		});

		req.done(function () {
			$box.slideUp();
		});

		req.fail(function (result) {
			if (result.status === 400 || result.status === 403) {
				const serverResponse = JSON.parse(result.responseText);
				if (serverResponse.Error) {
					console.error(serverResponse.Error);
					window.translations.error[serverResponse.Error] ?
							showErrorToast(window.translations.error[serverResponse.Error]):
							showErrorToast(window.translations.error.address_deletion_error);
				}
			} else {
				showErrorToast(window.translations.error.address_deletion_error);
			}
		});
	});

	/***** Customer data form handling *****/
	const $personalDataForm = $('#personalDataForm');
	if ($personalDataForm) {
		let $error = $('#personalDataForm div#error');
		let $success = $('#personalDataForm div#success');
		removeCustomValidity('#personalDataForm');
		$personalDataForm.on('submit', function (e) {
			e.preventDefault();
			const $form = $(this);

			$form.addClass('was-validated');
			if (!$form[0].checkValidity()) {
				return
			}
			$(".t-button").attr("disabled", true);

			const vals = $form.serializeArray();
			const data = {};

			$.map(vals, function(n, i){
				data[n['name']] = n['value'];
			});

			let req = $.ajax({
				url: $form.attr('action'),
				data: JSON.stringify(data),
				method: 'PUT',
				contentType: 'application/json'
			});

			req.done(function (_) {
				$(".t-button").removeAttr("disabled");
				window.location.reload();

			});

			req.fail(function (result) {
				$(".t-button").removeAttr("disabled");
				if (result.status === 400 || result.status === 403) {
					const serverResponse = result.responseJSON;
					if (serverResponse && serverResponse.Error) {
						revalidate(serverResponse, $form);
						switch (serverResponse.Error) {
							case "pec address already present":
								showErrorToast(`${window.translations.error.pecAlreadyPresent} ${serverResponse.Args[0]}`);
								break;
							case "text contains non-alphanumeric characters":
								showErrorToast(window.translations.error.name);
								break;
							default:
								window.translations.error[serverResponse.Error] ?
										showErrorToast(window.translations.error[serverResponse.Error]):
										showErrorToast(window.translations.error.update_personal_data);
						}
					}
				} else {
					showErrorToast(window.translations.error.update_personal_data);
				}
			});

		});
	}


});
