import {notify, revalidate, removeCustomValidity} from "./index";

$(document).ready(function() {
	// ----------------------------------------------- Cookie start
	window.setCookie = setCookie;
	window.removeCookie = removeCookie;
	window.getCookieToken = getCookieToken;

	function setCookie (ukey) {
		var date = new Date();
		date.setTime(date.getTime() + (5 * 24 * 60 * 60 * 1000));
		document.cookie = 'ukey=' + ukey + ";expires=" + date.toUTCString() + ";path=/;SameSite=Lax;";
	}

	function getCookieToken () {
		return getCookie("ukey")
	}

	function getCookie (cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function removeCookie () {
		var date = new Date(0);
		document.cookie = 'ukey= ; expires=' + date.toUTCString() + ';path=/;';

	}

	// ----------------------------------------------- Cookie end
	function showMagoUserModal(mail) {
		$('#mago-user-modal-link').attr("href", `/password-reset/request/${mail}`)
		$('#mago-user-modal').modal();
	}

	// If anonymous user, save token
	if (!getCookieToken()) {
		setCookie($('#customerToken').val())
	}

	// Users logs in via username and password
	var $loginForm = $('#loginForm');
	if ($loginForm.length) {
		var $anonymToken = $('#customerToken');
		var $uname = $('#loginForm input#username');
		var $password = $('#loginForm input#password');
		var $loginError = $('#loginForm div#login-error');
		$loginForm.on('submit', function(e) {
			e.preventDefault();
			$loginError.css('display', 'none');
			$("#loginForm .t-button").attr("disabled", true);
			var anonymToken = $anonymToken.val();
			var uname = $uname.val();
			var pass = $password.val();
			var req = $.ajax({
				url: '/api/login',
				data: {
					anonymToken: anonymToken,
					username: uname,
					password: pass
				},
				method: 'POST',
			});

			req.done(function(obj) {
				setCookie(obj.Key);
				if ($loginForm.data("nexturl")) {
					window.location.href = $loginForm.data("nexturl");
				} else {
					window.location.href = '/';
				}
				$loginError.css('display', 'none');
				$("#loginForm .t-button").attr("disabled", false);
			});

			req.fail(function(result) {
				$("#loginForm .t-button").attr("disabled", false);
				$uname.text('');
				$password.text('');
				if (window.translations.error[result.responseJSON.ErrorCode]) {
					showErrorToast(window.translations.error[result.responseJSON.ErrorCode]);
				} else {
				showErrorToast(window.translations.error.login);
				}
			});
		});
	}


	var $registerForm = $('#registerForm');

	if ($registerForm.length) {
		const $mail = $('#registerForm input#mail');
		const $regPassword = $('#registerForm input#register-password');
		const $password2 = $('#registerForm input#password2');
		const $name = $('#registerForm input#name');
		const $surname = $('#registerForm input#surname');
		const $companyName = $('#registerForm input#companyname');
		const $error = $('#registerForm div#register-error');
		const $checkMail = $('#registerForm div#register-check-mail');

		removeCustomValidity('#registerForm');

		const $isCompany = $('#isCompany');
		const $isPerson = $('#isPerson');
		const $companyItems = $('[data-mode="company"]');
		$isCompany.on('change', function(e) {
			const isCompany = $(this).is(':checked');
			if (isCompany) {
				$companyItems.removeClass('show-out').addClass('show-in');
				$companyItems.prop('required', true);
			}
		});

		$isPerson.on('change', function(e) {
			const isPerson = $(this).is(':checked');
			if (isPerson) {
				$companyItems.removeClass('show-in').addClass('show-out');
				$companyItems.prop('required', false);
			}
		});

		$registerForm.on('submit', function(e) {
			e.preventDefault();
			const $form = $(this);

			const minlen = $regPassword.attr('minlength');
			const maxlen = $regPassword.attr('maxlength');

			const plen = $regPassword.val().length;
			if (plen < minlen || plen > maxlen) {
				const msg = $regPassword.data('original-title');
				$regPassword.get(0).setCustomValidity(msg);
				$form.addClass('was-validated');
				showErrorToast(msg)
			} else if ($regPassword.val() !== $password2.val()) {
				$password2.get(0).setCustomValidity(window.translations.error.password_dont_match);
				$form.addClass('was-validated');
				showErrorToast(window.translations.error.password_dont_match);
			}

			$form.addClass('was-validated');
			if (!$form[0].checkValidity()) {
				return
			}

			$error.css('display', 'none');
			$checkMail.css('display', 'none');



			const mail = $mail.val();
			const pass = $regPassword.val();
			const name = $name.val();
			const surname = $surname.val();
			const ctype = $('#registerForm input[name="ctype"]:checked').val();
			const companyname = $companyName.val();


			$("#registerForm .t-button").attr("disabled", true);
			let data = {
				email: mail,
				password: pass,
				name: name,
				surname: surname,
				ctype: ctype,
				companyname: companyname,
			};

			let language = $registerForm.data("language");
			let req = $.ajax({
				url: '/' + language + '/api/frontend/customer',
				data: data,
				method: 'POST',
			});

			req.done(function(_) {
				$error.css('display', 'none');
				$("#registerForm .t-button").attr("disabled", false);
				if ($registerForm.data("nexturl")) {
					window.location.href = $registerForm.data("nexturl");
				} else {
					$checkMail.css('display', 'block');
					$registerForm.trigger("reset");
				}
			});

			req.fail(function(result) {
				$("#registerForm .t-button").attr("disabled", false);
				if (result.responseJSON.Field) {
					// spellbook.FieldError with field, error and args[]
						if (result.responseJSON.Args[0] === "imported_MAGO_user") {
							// Show restore password message
							showMagoUserModal(mail);
						} else {
							revalidate(result.responseJSON, $form);
							showErrorToast(window.translations.error[result.responseJSON.Error]);
						}
				} else  {
						showErrorToast(window.translations.globals.error.generic_error);
					}
			});

		});
	}

	var $logoutButton = $('a#logout-button');
	if ($logoutButton) {
		$logoutButton.on('click', function () {
			removeCookie();
			window.location.href = '/';
		});
	}

	var $reserPasswordButton = $('#reset-password');
	if ($reserPasswordButton) {
		$reserPasswordButton.on('click', function () {
			const $emailField = $('#username');
			const email = $emailField.val();
			const language = $(this).data("language");
			if (email) {
				window.location.href = `/${language}/password-reset/request/${email}`;
			} else {
				showErrorToast(window.translations.error.fill_email_field)
			}
		});
	}

});
