import {notify} from './index';

$(document).ready(function() {

	const $container = $('.checkout-order-summary, .order_detail');
	if (!$container.length) {
		return
	}

	/**
	 * Customer activation handling
	 */
	$('#activate-customer').on('click', function () {
		const $acloader = $('span#activate-customer-loader');
		const language = $(this).data("language");
		const req = $.ajax(
			{
				method: 'GET',
				url: `/${language}/api/request-customer-activation`
			}
		);

		$acloader.show();
		req.done(function(result) {
			if (result.Error) {
				window.showErrorToast(window.translations.error.user_activation.error_activate_customer);
			} else {
				$('#activate-customer-box').hide();
				$('#activate-customer-success').show();
			}
			$acloader.hide();
		});

		req.fail(function() {
			window.showErrorToast(window.translations.error.user_activation.error_activate_customer);
			$acloader.hide();
		});

		req.always(function() {
			$acloader.hide();
		});
	});

	const vars = window.summary();

	/**
	 * Coupon handling
	 * @type {jQuery|HTMLElement}
	 */
	const $computeCoupon = $('#compute-coupon');
	const $couponAction = $('#coupon-action');
	const $coupon = $('#coupon-code');
	const $discounted = $('div#discounted-total');
	const $couponValue = $('span#coupon-value');
	const $dp = $('span#discounted');
	const $full = $('span#full');
	const $cform = $('form#coupon-form');
	const $cloader = $('span#coupon-loader');
	$coupon.on('input', function(e) {
		const code = $(this).val();
		$computeCoupon.prop('disabled', !code.length)
	});

	$cform.on('submit', function(e) {
		e.preventDefault();
		const code = $coupon.is(':disabled') ? null : $coupon.val();
		const isAdd = !vars.coupon;
		const req = $.ajax(
			{
				method: 'PATCH',
				url: `/api/frontend/order/${vars.orderId}`,
				contentType: 'application/json',
				data: JSON.stringify({coupon: code}),
			}
		);

		$cloader.show();
		req.done(function(order) {
			if (isAdd) {
				// coupon has been added, change the input value and show the discount
				$coupon.val(order.coupon.code);
				$coupon.prop('disabled', true);
				$discounted.show();
				$couponAction.text(window.translations.coupon.remove);
				vars.coupon = order.coupon.code;
				vars.amount = order.fullPrice;	// AKA OrderPriceToPay
				$dp.text("€ " + roundTwoDecimals(order.undiscountedPrice));
				$full.text("€ " + roundTwoDecimals(order.fullPrice));
				$couponValue.text("€ " + roundTwoDecimals(order.vatRegime == "STD" ? order.vatCouponDiscount : order.netCouponDiscount));
			} else {
				// coupon has been removed, change the input value and restore the full price
				$discounted.hide();
				$coupon.prop('disabled', false);
				$coupon.val(null);
				$couponAction.text(window.translations.coupon.add);
				vars.coupon = null;
				vars.amount = order.fullPrice;	// AKA OrderPriceToPay
				$dp.text(null);
				$full.text("€ " + roundTwoDecimals(order.fullPrice));
				$couponValue.text("€ 0");
			}
		});

		req.fail(function(jqxhr) {
			switch (jqxhr.status) {
				case 400:
					showErrorToast(translations.error.coupon.invalid);
					break;
				default:
					console.error(jqxhr);
					showErrorToast(window.translations.globals.error.generic_error);
			}

		});

		req.always(function() {
			$cloader.hide();
		});
	});

	const clientToken = vars.btClient;

	function startPaymentProcess(payWith, btToken) {
		switch (payWith) {
			case 'card':
				let $braintreePaymentUrl = vars.btUrl;
				if (btToken) {
					$braintreePaymentUrl += `/${clientToken}/pmtoken/${btToken}`
				} else {
					$braintreePaymentUrl += `/${clientToken}`
				}
				window.location.href = $braintreePaymentUrl;
				break;
			case 'bank':
				window.location.href = vars.bankUrl;
				break;
		}
	}


	// ----------- FORM --------------
	$('#checkoutSummaryForm').on('submit', function(e) {
		e.preventDefault();
	});

	$('.submitButton').on('click', function () {
		switch ($(this).attr('id')) {
			case 'checkout-bank':
				submitConsentsForm(function() {
					startPaymentProcess('bank', null)
				});
				break;
			case 'checkout-card':
				submitConsentsForm(function() {
					startPaymentProcess('card', null)
				});
				break;
			default:
				// Selected one of the vaulted payment methods
				const token = $(this).data('token');
				submitConsentsForm(function() {
					startPaymentProcess('card', token)
				});
				break;
		}

	});

	function submitConsentsForm(onSuccess, onError) {
		const $form = $('#checkoutSummaryForm');
		const vals = $form.serializeArray();

		const method = $form.attr('method');
		let url = $form.attr('action');

		const req = $.ajax({
			url: url,
			data: vals,
			method: method
		});

		req.done(function (result) {
			if (onSuccess) {
				onSuccess();
			}
		});

		req.fail(function (result) {
			if (result.responseText) {
				const serverResponse = JSON.parse(result.responseText);
				if (serverResponse.Error) {
					var locErr = window.translations.error.missing_consents.error_generic;
					if (serverResponse.ErrorCode) {
						const le = window.translations.error.missing_consents[serverResponse.ErrorCode];
						if (le) {
							locErr = le;
						}
					}
					showErrorToast(locErr);
				} else {
					showErrorToast(window.translations.error.order_submit.error_generic);
				}
			} else {
				showErrorToast(window.translations.error.order_submit.error_generic);
			}
			if (onError) {
				onError();
			}
		});
	}

	function freezeSelectedConsentCheckboxes() {
		$('#conditions').find('input[type=checkbox]:checked').attr('disabled', '');
	}

	function roundTwoDecimals(n) {
		return Number.parseFloat(n).toFixed(2)
	}



	// ----------- BRAINTREE ---------------
	const braintree = require('braintree-web');
	const btClient = require('braintree-web/client');
	btClient.create({
		authorization: clientToken
	}).then(function (clientInstance) {
		return braintree.paypalCheckout.create({
			client: clientInstance
		});
	}).then(function (paypalCheckoutInstance) {
		return paypal.Buttons({

			onClick: function(data, actions) {
				return new Promise((resolve, reject) => {
					submitConsentsForm(function () {
						if (checkOrderValid()) {
							$('html').append('<div class="loading-page"><div class="spinner-border text-green cap-spinner" role="status"><span class="sr-only">Loading...</span></div><div class="t-title--small p-top-s">'+ window.translations.globals.wait +'</div></div>');
							resolve()
						} else {
							reject()
						}
					}, function (err) {
						reject(err)
					});
				}).then(_ => {
					return actions.resolve();
				}).catch(err => {
					console.error('rejected', err);
					return actions.reject();
				});
			},

			style: {
				size: 'responsive',
				color: 'gold',
				shape: 'rect',
				label: 'pay',
				layout: 'horizontal',
				tagline: 'false'
			},

			createOrder: function () {
				return paypalCheckoutInstance.createPayment({
					flow: 'checkout',
					enableShippingAddress: false,
					shippingAddressEditable: false,
					amount: parseFloat(vars.amount),
					currency: 'EUR'
				});
			},

			onApprove: function (data, actions) {
				return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
					const paymentUrl = vars.paypalpaymenturl;
					window.location.href = `${paymentUrl}/${payload.nonce}`
				});
			},

			onCancel: function () {
				$(".loading-page").remove();
				console.error('checkout.js payment cancelled');
			},

			onError: function (err) {
				$(".loading-page").remove();
				console.error('checkout.js error', err);
			},

			// env: 'sandbox', //'production'
		}).render('#paypal-button');
	}).catch(function (err) {
		console.error('Error creating PayPal Checkout:', err);
	});

	function checkOrderValid() {
		if (!vars.orderValid) {
			showErrorToast(window.translations.error.order_submit.error_invalid_order);
			return false;
		}
		return true;
	}

});
