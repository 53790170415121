$(document).ready(function() {

    var $pageId = $('#discover');
    if (!$pageId.length) {
        return;
    }

    $(".discover-text-toggle").on("click", function () {
        $(this).children(".discover-text-container").toggleClass("show");
    });

    const $toggle = $('.discover-text-toggle');
    const $container = $('.discover-text-container')
    $(document).mouseup(function (e) {
        if (!$toggle.is(e.target) && $toggle.has(e.target).length === 0)
        {
            $container.removeClass('show');
        }
    })
});
