const braintree = require('braintree-web');
let $container;

$(document).ready(function () {
	$container = $('.checkout-braintree');
	if (!$container.length) {
		// Payment done, we are showing a success/error page
		return
	}

	var submitSpinner = $container.find("#submit-spinner");

	var form = document.querySelector('#braintree-payment-form');
	var submit = document.querySelector('input[type="submit"]');
	var clientToken = $container.data("clientid");

	// Setup braintree payment stack
	setupComponents(clientToken).then(function (instances) {
		hf = instances[0];
		threeDS = instances[1];
		submit.removeAttribute('disabled');
		submitSpinner.hide();
	}).catch(function (err) {
		console.log('component error:', err);
	});

	form.addEventListener('submit', function (event) {
		// Pay button clicked
		event.preventDefault();
		submit.setAttribute('disabled', 'disabled');
		submitSpinner.show();

		let customerData = window.customerData;
		hf.tokenize().then(function (payload) {
			return threeDS.verifyCard({
				onLookupComplete: function (data, next) {
					next();
				},
				amount: customerData.amount,
				nonce: payload.nonce,
				bin: payload.details.bin,
				email: customerData.email,
			})
		}).then(function (payload) {
			console.log('verification success:', payload);
			sendNonceToServer(payload);
		}).catch(function (tokenizeErr) {
			switch (tokenizeErr.code) {
				case 'HOSTED_FIELDS_FIELDS_EMPTY':
					showErrorToast(window.translations.error.fill_all_fields);
					break;
				case 'HOSTED_FIELDS_FIELDS_INVALID':
					showErrorToast(window.translations.error.invalid_card_data);
					break;
				default:
					showErrorToast(tokenizeErr.message);
			}
			console.error(tokenizeErr);
			submit.removeAttribute('disabled');
			submitSpinner.hide();
			return;
		});
	});

});


function setupComponents(clientToken) {
	const localizedContent = window.localizedContent;
	return Promise.all([
		braintree.hostedFields.create({
			authorization: clientToken,
			styles: {
				'input': {
					'height': '54px',
					'font-family': '\'Lato-Light\', sans-serif',
					'font-size': '1.125rem',
					'line-height': '1.2',
				},
				'input.invalid': {
					'color': 'red'
				},
				'input.valid': {
					'color': 'green'
				},
			},
			fields: {
				number: {
					selector: '#card-number',
					placeholder: localizedContent.card_number
				},
				cvv: {
					selector: '#cvv',
					placeholder: localizedContent.card_cvv2
				},
				expirationDate: {
					selector: '#expiration-date',
					placeholder: localizedContent.card_exp
				}
			}
		}),
		braintree.threeDSecure.create({
			authorization: clientToken,
			version: 2
		})
	]);
}


/**
 * Called when verification (including 3DS) succeeded.
 * Sends Nonce to server
 */
function sendNonceToServer(payload) {
	const paymentUrl = $container.data("paymenturl");
	window.location.href = `${paymentUrl}/${payload.nonce}`
}

