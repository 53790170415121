$(document).ready(function () {

	var $pageId = $('#product-page');
	if (!$pageId.length) {
		return;
	}

	var $productID = $('.product input#productID');
	var $quantity = $('.product input#quantity');

	$(".button_purchase").on("click", function () {
		$(this).attr("disabled", true);
		// Google ads: Event snippet for Aggiunta al carrello CALDOLIVA conversion page
		if (window.caldini.cartCount == 0) {
			gtag('event', 'conversion', {'send_to': 'AW-997987610/ZT9pCMmj2tADEJqq8NsD'});
		}

		caldini.purchase($productID.val(), $quantity.val());
	});

	$('.product_images').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	});
});
