import {displayVat} from "./index";

$(document).ready(function () {

	var $pageId = $('#cart-page');
	if (!$pageId.length) {
		return;
	}

	$(".cart_item .remove").on("click", function () {
		const cartItem = $(this).parents(".cart_item");
		const purchaseID = cartItem.data("purchaseid");
		let req = $.ajax({
			url: `/api/frontend/purchase/${purchaseID}`,
			method: 'DELETE',
		});

		req.done(function () {
			window.location.reload();
		});

		req.fail(function (result) {
			if (result.status === 404) {
				showErrorToast(window.translations.error.error_notfound);
			} else {
				showErrorToast(window.translations.error.error_delete);
			}
		});
	});

	$(".cart_item .quantity .counter_plus").on("quantityChanged", function (event, quantity) {
		modifyQuantity($(this), quantity);
	});
	$(".cart_item .quantity .counter_minus").on("quantityChanged", function (event, quantity) {
		modifyQuantity($(this), quantity);
	});

	$(".cart_item .quantity .counter_number").on("quantityChanged", function (event, quantity){
		modifyQuantity($(this), quantity);
	});

	function modifyQuantity(ctx, quantity) {
		const cartItem = ctx.parents(".cart_item");
		const purchaseID = cartItem.data("purchaseid");
		const updatedmessage = cartItem.data("updatedmessage");

		const data = {
			id: `${purchaseID}`,
			quantity: quantity,
		};
		let req = $.ajax({
			url: `/api/frontend/purchase/${purchaseID}`,
			data: JSON.stringify(data),
			method: 'PUT',
			contentType: 'application/json'
		});

		req.done(function (result) {
			hideError();
			const orderId = $(".cart").data("orderid");
			reloadOrder(orderId);
			showMessageToast(updatedmessage)
		});

		req.fail(function (result) {
			if (result.status === 404) {
				showErrorToast(window.translations.error.error_notfound);
			} else {
				showErrorToast(window.translations.error.error_put);
			}
		});
	}

	const $goodsPrice = $('#goods-price');
	const $deliveryPrice = $('#delivery-price');
	const $priceToPay = $('#price-to-pay');

	const $deliveryText = $('#delivery-text');

	const $itemsCount = $('.cart-count');

	function textForDeliveryType(type) {
		switch (type) {
			case 'ship':
				return window.delivery.shipping;
			case 'collect':
				return window.delivery.collect;
			case 'conditioned':
				return window.delivery.conditioned;
			default:
				return window.delivery.default;
		}
	}

	function reloadOrder(id) {
		let req = $.ajax({
			url: `/api/frontend/order/${id}`,
			method: 'GET',
			contentType: 'application/json'
		});

		req.done(function (order) {
			if (!order.purchases.length) {
				window.location.reload();
			}

			const price = displayVat() ? order.vatPrice : order.price;
			$priceToPay.html(`€ ${price.toFixed(2)}`);
			$priceToPay.data('price', `€ ${order.price.toFixed(2)}`);
			$priceToPay.data('vat_price', `€ ${order.vatPrice.toFixed(2)}`);

			if (!$deliveryPrice.data('must-compute')) {
				const dprice = displayVat() ? order.vatTotalShippingPrice : order.netTotalShippingPrice;
				$deliveryPrice.html(dprice === 0 ? null : `€ ${dprice.toFixed(2)}`);
				$deliveryPrice.data('price', `€ ${order.netTotalShippingPrice.toFixed(2)}`);
				$deliveryPrice.data('vat_price', `€ ${order.vatTotalShippingPrice.toFixed(2)}`);

				if (order.deliveryType !== 'conditioned') {
					const dtext = textForDeliveryType(order.deliveryType);
					$deliveryText.text(dtext);
				}

			}

			const gprice = displayVat() ? order.vatGoodsPrice : order.netGoodsPrice;
			$goodsPrice.html(`€ ${gprice.toFixed(2)}`);
			$goodsPrice.data('price', `€ ${order.netGoodsPrice.toFixed(2)}`);
			$goodsPrice.data('vat_price', `€ ${order.vatGoodsPrice.toFixed(2)}`);

			let itemsCount = 0;
			order.purchases.forEach(purchase => {
				itemsCount += purchase.quantity;
				const price = displayVat() ? purchase.totalVatPrice : purchase.totalNetPrice;
				$(`#price-purchase-${purchase.id}`)
						.html(`€ ${price.toFixed(2)}`)
						.attr('data-price', `€ ${purchase.totalNetPrice.toFixed(2)}`)
						.attr('data-vat_price', `€ ${purchase.totalVatPrice.toFixed(2)}`)
			});

			$itemsCount.text(itemsCount);

			submitDisabled(!order.isCartValid);
		});

		req.fail(function (result) {
			if (result.status === 404) {
				// when remove last purchase in order: this order is deleted!
				window.location.reload();
				return;
			} else {
				showErrorToast(window.translations.error.error_put);
			}
			$('.total_price').html('€ 0');
			$('#buy_button').hide();
		});
	}

	function submitDisabled(disabled) {
		if (disabled) {
			$('#buy_button').addClass('disabled');
			$('#invalid_cart').removeClass('invisible');
		} else {
			$('#buy_button').removeClass('disabled');
			$('#invalid_cart').addClass('invisible');
		}
	}
});
